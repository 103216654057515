import React from "react";
import ServicesUs from "../Components/OurServerices/servicesUs";
import OurServericesPageHeader from "../Components/OurServerices/ourservericesPageHeader";
import TranslatHook from "../hook/translat-hook";

const OurServericesPage = () => {
  const [lang] = TranslatHook();

  return (
    <>
      <div style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
        <OurServericesPageHeader />
        <ServicesUs />
      </div>
    </>
  );
};

export default OurServericesPage;
