import React, { useState, useEffect } from "react";
import img1 from "../../images/img/Maintenance.png";
import img2 from "../../images/Noon/Sales2.jpeg";
import img3 from "../../images/Noon/camera.jpeg";
import classNames from "classnames";
import { Link } from "react-router-dom";

const CitiesSlider = ({ slides }) => {
  const IMAGE_PARTS = 4;
  const AUTOCHANGE_TIME = 1000000;

  let changeTO = 7;

  const [activeSlide, setActiveSlide] = useState(-1);
  const [prevSlide, setPrevSlide] = useState(-1);
  const [sliderReady, setSliderReady] = useState(false);

  useEffect(() => {
    runAutochangeTO();
    setTimeout(() => {
      setActiveSlide(0);
      setSliderReady(true);
    }, 0);

    return () => {
      clearTimeout(changeTO);
    };
  }, []);

  useEffect(() => {
    resetAutochangeTO();
  }, [activeSlide]);

  const runAutochangeTO = () => {
    changeTO = setTimeout(() => {
      changeSlides(1);
    }, AUTOCHANGE_TIME);
  };

  const resetAutochangeTO = () => {
    clearTimeout(changeTO);
    runAutochangeTO();
  };

  const changeSlides = (change) => {
    clearTimeout(changeTO);
    const length = slides.length;
    let newActiveSlide = activeSlide + change;
    if (newActiveSlide < 0) newActiveSlide = length - 1;
    if (newActiveSlide >= length) newActiveSlide = 0;
    setActiveSlide(newActiveSlide);
    setPrevSlide(activeSlide);
  };

  return (
    <div className={classNames("slider", { "s--ready": sliderReady })}>
      <div className="slider__slides">
        {slides.map((slide, index) => (
          <div
            className={classNames("slider__slide", {
              "s--active": activeSlide === index,
              "s--prev": prevSlide === index,
            })}
            key={slide.city}
          >
            <div className="slider__slide-content">
              <h3 className="slider__slide-subheading">
                {slide.country || slide.city}
              </h3>
              <h2 className="slider__slide-heading">
                <span>{slide.city}</span>
              </h2>
              <Link to={slide.link}>
                {" "}
                <p className="slider__slide-readmore"> إقرأ  المزيد</p>
              </Link>
            </div>
            <div className="slider__slide-parts" style={{}}>
              {[...Array(IMAGE_PARTS)].map((x, i) => (
                <div className="slider__slide-part" key={i}>
                  <div
                    className="slider__slide-part-inner"
                    style={{
                      backgroundImage: `url(${slide.imgj})`,
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="slider__control" onClick={() => changeSlides(-1)} />
      <div
        className="slider__control slider__control--right"
        onClick={() => changeSlides(1)}
      />
    </div>
  );
};

const slides = [
  {
    city: "انظمة حماية",
    imgj: img3,
    link: "/oursvices/2",
  },
  {
    city: "الصيانة",
    country: "الصيانة",
    imgj: img1,
    link: "/oursvices/3",
  },
  {
    city: "مبيعات المؤسسات",
    country: "مبيعات المؤسسات",
    imgj: img2,
    link: "/oursvices/0",
  },
];

const HomeMainSlider = () => {
  return <CitiesSlider slides={slides} />;
};

export default HomeMainSlider;
