import React from "react";
import dataa from "../../../dataa";
import TranslatHook from "../../../hook/translat-hook";
import { useParams } from "react-router-dom";

const LeftSideComponentOurServeice = () => {
  const { id } = useParams();

  const [dataServes] = dataa();
  const [lang] = TranslatHook();
  return (
    <>
      <div className="col-lg-4 col-md-5" style={{ direction: "ltr" }}>
        <div
          className="image widget heading_space_half"
          key={dataServes[id].id}
        >
          <img src={dataServes[id].image} alt="ServicesImage" />
        </div>

        <div className="col-12 px-0"></div>
        <div className="widget heading_space text-center text-md-left">
          <h4 className="text-capitalize darkcolor bottom30">
            {lang === "ar" ? "للمزيد من المعلومات" : "Need Help"}
          </h4>
          <div className="contact-table d-table bottom15 text-left">
            <div className="d-table-cell cells">
              <span className="icon-cell">
                <i class="fa-regular fa-user"></i>{" "}
              </span>
            </div>
            <div className="d-table-cell cells">
              <p className="bottom0">{dataServes[id].user}</p>
            </div>
          </div>
          <div className="contact-table d-table bottom15 bodyen">
            <div className="d-table-cell cells">
              <span className="icon-cell">
                <i className="fas fa-mobile-alt"></i>
              </span>
            </div>

            <div
              className="d-table-cell cells "
              style={{ fontFamily: "Montserrat, sans-serif" }}
            >
              <p className="bottom0">{dataServes[id].phone}</p>
            </div>
          </div>
          <div className="contact-table d-table bottom15">
            <div className="d-table-cell cells">
              <span className="icon-cell">
                <i class="fa-regular fa-envelope"></i>
              </span>
            </div>

            <div className="d-table-cell cells">
              <p
                className="bottom0"
                style={{ fontFamily: "Montserrat, sans-serif" }}
              >
                {" "}
                {dataServes[id].email}              </p>
            </div>
          </div>

          <div className="contact-table d-table text-left">
            {/* <div className="d-table-cell cells">
              <span className="icon-cell">
                <i className="far fa-clock"></i>
              </span>
            </div> */}
            {/* <div className="d-table-cell cells">
              <p className="bottom0">
                Mon to Sat - 9:00am to 6:00pm
                <span className="d-block">Sunday: Closed</span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSideComponentOurServeice;
