import React, { useState } from 'react'
import '../../styles/select.css'
import TranslatHook from '../../hook/translat-hook'

const SelectDepartment = () => {
    const [lang, content] = TranslatHook()
    const [value1, setValue] = useState('noontek')
    const handelClick = (e) => {
        setValue(e.target.value)
        console.log(value1)
    }
    return (

        <fieldset className="checkbox-group">
            <legend className="checkbox-group-legend" name='department' style={{ textAlign: lang === 'ar' ? 'right' : 'left' }} value={content.department}>{content.department} :  </legend>
            <input type='text' value={content.depart} name="depart" hidden />
            <input type='radio' value={value1} name="favorites" defaultChecked hidden />
            <div className="checkbox">
                <label className="checkbox-wrapper">
                    <input type="radio" className="checkbox-input" name="favorites"
                        value={content.ecommerce} onClick={handelClick} />
                    <span className="checkbox-tile">
                        <span className="checkbox-icon">
                            <rect width="256" height="256" fill="none"></rect>
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M64 0C46.3 0 32 14.3 32 32V96c0 17.7 14.3 32 32 32h80v32H87c-31.6 0-58.5 23.1-63.3 54.4L1.1 364.1C.4 368.8 0 373.6 0 378.4V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V378.4c0-4.8-.4-9.6-1.1-14.4L488.2 214.4C483.5 183.1 456.6 160 425 160H208V128h80c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H64zM96 48H256c8.8 0 16 7.2 16 16s-7.2 16-16 16H96c-8.8 0-16-7.2-16-16s7.2-16 16-16zM64 432c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm48-168a24 24 0 1 1 0-48 24 24 0 1 1 0 48zm120-24a24 24 0 1 1 -48 0 24 24 0 1 1 48 0zM160 344a24 24 0 1 1 0-48 24 24 0 1 1 0 48zM328 240a24 24 0 1 1 -48 0 24 24 0 1 1 48 0zM256 344a24 24 0 1 1 0-48 24 24 0 1 1 0 48zM424 240a24 24 0 1 1 -48 0 24 24 0 1 1 48 0zM352 344a24 24 0 1 1 0-48 24 24 0 1 1 0 48z"/></svg>

                        </span>
                        <span className="checkbox-label">{content.ecommerce}</span>
                    </span>
                </label>
            </div>

            <div className="checkbox">
                <label className="checkbox-wrapper">
                    <input type="radio" className="checkbox-input" name="favorites"
                        value={content.maintenance} onClick={handelClick} />
                    <span className="checkbox-tile">
                        <span className="checkbox-icon">
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h54.1l109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109V104c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7H352c-8.8 0-16-7.2-16-16V102.6c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" /></svg>
                        </span>
                        <span className="checkbox-label">{content.maintenance}</span>
                    </span>
                </label>
            </div>
            <div className="checkbox">
                <label className="checkbox-wrapper">
                    <input type="radio" className="checkbox-input" name="favorites"
                        value={content.sales} onClick={handelClick} />
                    <span className="checkbox-tile">
                        <span className="checkbox-icon">

                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M117.9 62.4c-16.8-5.6-25.8-23.7-20.2-40.5s23.7-25.8 40.5-20.2l113 37.7C265 15.8 290.7 0 320 0c44.2 0 80 35.8 80 80c0 3-.2 5.9-.5 8.8l122.6 40.9c16.8 5.6 25.8 23.7 20.2 40.5s-23.7 25.8-40.5 20.2L366.4 145.2c-4.5 3.2-9.3 5.9-14.4 8.2V480c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32s14.3-32 32-32H288V153.3c-21-9.2-37.2-27-44.2-49l-125.9-42zM200.4 288L128 163.8 55.6 288H200.4zM128 384C65.1 384 12.8 350 2 305.1c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8l95.2 163.2c5.7 9.8 9.3 21.1 6.7 32.1C243.2 350 190.9 384 128 384zm382.8-92.2L438.4 416H583.3L510.8 291.8zm126 141.3C626 478 573.7 512 510.8 512s-115.2-34-126-78.9c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8l95.2 163.2c5.7 9.8 9.3 21.1 6.7 32.1z" /></svg>

                        </span>
                        <span className="checkbox-label">{content.sales}</span>
                    </span>
                </label>
            </div>
            <div className="checkbox">
                <label className="checkbox-wrapper">
                    <input type="radio" className="checkbox-input" name="favorites"
                         value={content.institutions} onClick={handelClick} />
                    <span className="checkbox-tile">
                        <span className="checkbox-icon">
                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                        </span>
                        <span className="checkbox-label">{content.institutions}</span>
                    </span>
                </label>
            </div>
            <div className="checkbox">
                <label className="checkbox-wrapper">
                    <input type="radio" className="checkbox-input" name="favorites"
                        value={content.security} onClick={handelClick} />
                    <span className="checkbox-tile">
                        <span className="checkbox-icon">
                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8V444.8C394 378 431.1 230.1 432 141.4L256 66.8l0 0z"/></svg>
                        </span>
                        <span className="checkbox-label">{content.security}</span>
                    </span>
                </label>
            </div>



        </fieldset>
    )
}

export default SelectDepartment