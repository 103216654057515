import React, { useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";
import "../../styles/rate.css";
import RatingServise from "./ratingServise";
import { faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emailjs from "@emailjs/browser";
import RataServes from "./rataServes";
import TranslatHook from "../../hook/translat-hook";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ReatingForm = () => {
  const [lang, content] = TranslatHook();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_m1fvu8r",
        "template_9qhi81l",
        form.current,
        "QBNtLKCB3k2O9e0o-"
      )
      .then(
        (result) => {
          if (lang === "ar") toast.success("شكرا لك ، تم التقييم");
          else if (lang === "en") toast.success("Thank you, the been rate");
          else if (lang === "tr") toast.success("Teşekkür ederim, oran oldu");
        },
        (error) => {
          if (lang === "ar") toast.error("هناك مشكلة في الإرسال");
          else if (lang === "en")
            toast.error("There is a problem with the sending");
          else if (lang === "tr") toast.error("göndermede sorun var");
        }
      );
    e.target.reset();
  };

  return (
    <div className="container whitebox rateform">
      <div className="widget py-5 ">
        <div className="row gy-4 ">
          <Form ref={form} onSubmit={sendEmail}>
            <p className="notObliged">{content.notObliged}:</p>
            <div className="col-md-12 col-sm-6 s test">
              <div className="col-md-6  ">
                <div
                  className="heading-title  wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <Form.Group
                    className="mb-3 textBoxIcon formGroupinputBox"
                    controlId="Name"
                  >
                    <Form.Control
                      type="text"
                      placeholder="الاسم"
                      name="firstName"
                    />
                    <div
                      className="formicon"
                      style={{
                        left: lang === "ar" ? "10px" : "",
                        right: lang === "ar" ? "" : "10px",
                      }}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 textBoxIcon formGroupinputBox"
                    controlId="formBasicEmail"
                  >
                    <Form.Control
                      type="email"
                      placeholder="الايميل"
                      className="inputw"
                      name="email"
                    />
                    <div
                      className="formicon"
                      style={{
                        left: lang === "ar" ? "10px" : "",
                        right: lang === "ar" ? "" : "10px",
                      }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 justify-content-end">
                <div
                  className="heading-title  wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <Form.Group
                    className="mb-3 textBoxIcon formGroupinputBox"
                    controlId="formBasicEmail"
                  >
                    <Form.Control
                      type="text"
                      placeholder={content.phone}
                      className="inputw"
                      name="phone"
                    />
                    <div
                      className="formicon"
                      style={{
                        left: lang === "ar" ? "10px" : "",
                        right: lang === "ar" ? "" : "10px",
                      }}
                    >
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>

            <RataServes />

            <p className="rateformP"> اترك لنا رسالة </p>

            <Form.Group className="mb-3 service" controlId="Message">
              <Form.Control
                name="message"
                style={{ resize: "none" }}
                placeholder={content.message}
                as="textarea"
                rows={3}
              />
            </Form.Group>

            <div className="col-md-3 col-sm-3">
              <button
                type="submit"
                id="submit_btn1"
                className="contact_btn button gradient-btn w-100"
              >
                <h3>{content.submit}</h3>
              </button>
            </div>
          </Form>
        </div>
      </div>
      <ToastContainer className="svgtest" />
    </div>
  );
};

export default ReatingForm;
