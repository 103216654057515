import React from "react";
import judy from "../../images/NoonTekImagePARTNERS/judy.png";
import noonmar from "../../images/NoonTekImagePARTNERS/noonmar.png";
import Gunesh from "../../images/NoonTekImagePARTNERS/Gunesh.png";
import Asbıl from "../../images/NoonTekImagePARTNERS/Asbìl.png";
import Cozum from "../../images/NoonTekImagePARTNERS/Cozum.png";
import ALFAMAKS from "../../images/NoonTekImagePARTNERS/ALFAMAKS.png";
import nardc from "../../images/NoonTekImagePARTNERS/NARDC.png";
import SMART from "../../images/NoonTekImagePARTNERS/SMART.png";
import { Col, Container, Row } from "react-bootstrap";
import TranslatHook from "../../hook/translat-hook";

const HomeTestimonials = () => {
  const [lang, content] = TranslatHook();

  return (
    <section id="OurPartners">
      <Container>
        <Row>
          <Col lg={4} className="text-center">
            <span>{content.partners}</span>
            <h2 className="darkcolor">{content.Our_TRUSTED_Partners}</h2>
          </Col>
          <Col lg={8} style={{ height: "80%" }} className="logos text-center  ">
            <Row className="">
              <Col
                lg={4}
                md={4}
                sm={6}
                xs={12}
                style={{ padding: "20px" }}
                className="Plogo "
              >
                <img className="img Plogo-img" src={judy} alt="" />
              </Col>
              <Col
                lg={4}
                md={4}
                sm={6}
                style={{ padding: "20px" }}
                xs={12}
                className="Plogo"
              >
                <img className="img Plogo-img" src={noonmar} alt="" />
              </Col>
              <Col
                lg={4}
                md={4}
                sm={6}
                xs={12}
                style={{ padding: "20px" }}
                className="Plogo"
              >
                <img src={Gunesh} className="img Plogo-img" alt="" />
              </Col>
              <Col
                lg={4}
                md={4}
                sm={6}
                xs={12}
                style={{ padding: "20px" }}
                className="Plogo"
              >
                <img src={Asbıl} className="img Plogo-img" alt="" />
              </Col>
              <Col
                lg={4}
                md={4}
                sm={6}
                xs={12}
                style={{ padding: "" }}
                className="Plogo"
              >
                <img src={Cozum} className="img Plogo-img" alt="" />
              </Col>
              <Col
                lg={4}
                md={4}
                sm={6}
                xs={12}
                style={{ padding: "" }}
                className="Plogo"
              >
                <img src={ALFAMAKS} className="img Plogo-img" alt="" />
              </Col>
              <Col
                lg={4}
                md={4}
                sm={6}
                xs={12}
                style={{ padding: "" }}
                className="Plogo"
              >
                <img src={nardc} className="img Plogo-img" alt="" />
              </Col>

              <Col
                lg={4}
                md={4}
                sm={6}
                xs={12}
                style={{ padding: "" }}
                className="Plogo"
              >
                <img src={SMART} className="img Plogo-img" alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeTestimonials;
