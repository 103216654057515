import { SET_CONTENT, SET_LANGUAGE } from "../actions/actions"


const init = {
    lang: 'ar',
    content: {},
}

const Reducer = (state = init, action) => {

    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                lang: action.payload
            }
        case SET_CONTENT:
            return {
                ...state,
                content: action.payload
            }
        default:
            return state;
    }
}

export default Reducer