import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/revolution/navigation.css";
import "./styles/about.css";
import "./styles/contact.css";
import "./styles/Extra.css";
import "./styles/services.css";
import "./styles/team.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/style.css";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { stor } from "./stor";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={stor}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);
