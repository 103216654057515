import React from "react";
import { useParams } from "react-router-dom";
import dataa from "../../../dataa";
import SldierOurServices from "./SldierOurServices";

const Test4 = () => {
  const { id } = useParams();
  const [dataServes] = dataa();

  return (
    <>
      <div className="col-lg-8 col-md-7">
        <div className="widget heading_space_half  text-md-left">
          <h3 className="darkcolor font-normal bottom30">
            {dataServes[id].name}
          </h3>
          <p
            className="bottom30"
            style={{
              fontSize: "18px",
            }}
          >
            {dataServes[id].title}
          </p>

          <SldierOurServices />
        </div>
      </div>
    </>
  );
};

export default Test4;
