import React from "react";
import { Link } from "react-router-dom";
import "../../styles/about.css";
import img from "../../images/img/About.jpeg";
import TranslatHook from "../../hook/translat-hook";

const aboutPageHeader = () => {
  const [lang] = TranslatHook();

  return (
    <section
      id="main-banner-page"
      className="position-relative page-header contact-header section-nav-smooth parallax"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center 0px",
      }}
    >
      <div className="container">
        <div className="row">
          <div>
            <div className="page-titles whitecolor text-center padding">
              <h2 className="font-bold"> من نحن </h2>
              {/* <h3 className="font-light py-2">WE HAVE ALL WHAT YOU NEED</h3> */}
            </div>
          </div>
        </div>
        <div className="overlay overlay-dark opacity-6 z-index-1"></div>

        <div className="title-wrap header-wrap">
          <div className="row">
            <div className="col-lg-12 col-md-12 whitecolor">
              <h3
                className="float-left"
                style={{ float: lang === "ar" ? "right" : "left" }}
              >
                من نحن
              </h3>
              <ul
                className="breadcrumb top10 bottom10 "
                style={{ float: lang === "ar" ? "left" : "right" }}
              >
                <li className=" hover-light">
                  <Link to="/">الرئيسية</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default aboutPageHeader;
