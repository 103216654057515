import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import dataa from "../../dataa";
import TranslatHook from "../../hook/translat-hook";

const ServicesUs = () => {
  const [dataServes] = dataa();
  const [lang, content] = TranslatHook();
  const togoup = () => {
    window.scrollTo({ top: 0 });
  };
  return (
    <section id="our-services" className="padding bglight">
      <Container className="container">
        <div
          className="col-md-12 text-center heading_space wow fadeIn"
          data-wow-delay="300ms"
        >
          <h2
            className="heading bottom30 darkcolor font-light2"
            style={{ textAlign: "center" }}
          >
            {content.OUR_SERVICES}
            <span className="divider-center"></span>
          </h2>
          <div className="col-md- ourservice_p_text">
            <p className="mb-n3" style={{ textAlign: "center" }}>
              شركة متخصصة بالاتصالات و المعلوماتية و الحلول الالكترونية .
            </p>
          </div>
        </div>

        <Row>
          {dataServes.map((item, index) => {
            return (
              <Col md={4} style={{ paddingBottom: " 50px" }} key={index}>
                <div className="cbp-item digital graphics">
                  <div className="services-main">
                    <div className="image bottom10">
                      <div className="image">
                        <img alt="SEO" src={item.image} />
                      </div>
                      <div className="overlay">
                        <Link
                          to={`/oursvices/${item.id}`}
                          className="overlay_center border_radius"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="services-content text-center text-md-left">
                      <h3 className="bottom10 darkcolor">
                        <Link to={`/oursvices/${item.id}`} onClick={togoup}>
                          {item.name}{" "}
                        </Link>
                      </h3>
                      <p className="bottom15">
                        {item.title && item.title.length > 50 ? (
                          <>
                            {item.title.substring(0, 50)}{" "}
                            <Link to={`/oursvices/${item.id}`}>
                              {item.name}...{" "}
                              {lang === "ar" ? "عرض المزيد" : "Show More"}
                            </Link>
                          </>
                        ) : (
                          item.title
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default ServicesUs;
