import React from 'react'
import ContactHeader from '../Components/Contact/contactHeader'
import ContactUs from '../Components/Contact/contactUs'
import TranslatHook from '../hook/translat-hook';

const ContactPage = () => {
    const [lang] = TranslatHook();

    return (
        <>
            <div >
                <ContactHeader />
                <ContactUs />
            </div>
        </>
    )
}

export default ContactPage