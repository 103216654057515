import React from "react";
import TranslatHook from "../../hook/translat-hook";
import { Col, Container, Row } from "react-bootstrap";
const homeCareAbout = () => {
  const [lang, content] = TranslatHook();

  return (
    <>
      <section id="services-list" className="services-list">
        <Container
          data-aos="fade-up"
          className={lang === "ar" ? "services-margin-ar" : null}
        >
          <div className="mainH" style={{ textAlign: "center" }}>
            <h1 className="mainH">{content.Rateus}</h1>
          </div>

          {/* <!-- End Service Item --> */}

          <Row
            className=" gy-5 gx-5 "
            style={{ marginBottom: "175px", width: "93%", margin: "auto" }}
          >
            <Col
              className="col-lg-4 col-md-12 col-12  service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="icon flex-shrink-0">
                <i
                  className="fa-solid fa-laptop-medical"
                  style={{ color: "#379397" }}
                ></i>
              </div>

              <div>
                <h4 className="title" style={{ marginTop: "-15px" }}>
                  <a href="#" className="stretched-link">
                    {content.ProvidingInnovativeTechnologicalSolutions}
                  </a>
                </h4>
                <p className="description"></p>
              </div>
            </Col>
            <Col
              className="col-lg-4 col-md-12 col-12 service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="icon flex-shrink-0">
                <i
                  className="fa-regular fa-handshake"
                  style={{ color: " #fba017" }}
                ></i>
              </div>

              <div>
                <h4 className="title" style={{ marginTop: "-5px" }}>
                  <a href="#" className="stretched-link">
                    {content.SustainablePartnerships}
                  </a>
                </h4>
                <p className="description"></p>
              </div>
            </Col>
            <Col
              className="col-lg-4 col-md-12 col-12  service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="100"
              style={{ marginTop: "35px" }}
            >
              <div className="icon flex-shrink-0">
                <i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-transparency"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 6.5a6.5 6.5 0 0 1 12.346-2.846 6.5 6.5 0 1 1-8.691 8.691A6.5 6.5 0 0 1 0 6.5Zm5.144 6.358a5.5 5.5 0 1 0 7.714-7.714 6.5 6.5 0 0 1-7.714 7.714Zm-.733-1.269c.363.15.746.261 1.144.33l-1.474-1.474c.069.398.18.78.33 1.144Zm2.614.386a5.47 5.47 0 0 0 1.173-.242L4.374 7.91a5.958 5.958 0 0 0-.296 1.118l2.947 2.947Zm2.157-.672c.297-.166.577-.36.838-.576L5.418 6.126a6.016 6.016 0 0 0-.587.826l4.35 4.351Zm1.545-1.284c.216-.26.41-.54.576-.837L6.953 4.83a5.97 5.97 0 0 0-.827.587l4.6 4.602Zm1.006-1.822c.121-.374.204-.766.242-1.172L9.028 4.078c-.386.063-.76.163-1.118.296l3.823 3.824Zm.186-2.642a5.463 5.463 0 0 0-.33-1.144 5.46 5.46 0 0 0-1.144-.33l1.474 1.474Z" />
                  </svg>{" "}
                </i>
              </div>

              <div>
                <h4 className="title">
                  <a href="#" className="stretched-link">
                    {content.Transparency}
                  </a>
                </h4>
                <p className="description"></p>
              </div>
            </Col>
            <Col
              className="col-lg-4 col-md-12 col-12  service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="icon flex-shrink-0">
                <i
                  className="fa-solid fa-seedling"
                  style={{ color: " #22b95c" }}
                ></i>
              </div>

              <div>
                <h4 className="title" style={{ marginTop: "-5px" }}>
                  <a href="#" className="stretched-link">
                    {content.EnvironmentalSustainability}
                  </a>
                </h4>
                <p className="description"></p>
              </div>
            </Col>
            <Col
              className="col-lg-4 col-md-12 col-12 service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="icon flex-shrink-0">
                <i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-card-checklist"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                    <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                  </svg>
                </i>
              </div>

              <div>
                <h4 className="title">
                  <a href="#" className="stretched-link">
                    {content.EmpoweringIndividuals}
                  </a>
                </h4>
                <p className="description"></p>
              </div>
            </Col>
            <Col
              className="col-lg-4 col-md-12 col-12 service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="icon flex-shrink-0">
                <i
                  className="fa-solid fa-users"
                  style={{ color: "#255d61" }}
                ></i>
              </div>

              <div>
                <h4 className="title" style={{ marginTop: "-5px" }}>
                  <a href="#" className="stretched-link">
                    {content.SocialResponsibility}
                  </a>
                </h4>
                <p className="description"></p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default homeCareAbout;
