import React from 'react'
import AboutPageHeader from '../Components/About/aboutPageHeader'
import Aboutus from '../Components/About/aboutus'
import Aboutus2 from '../Components/About/aboutus2'
import '../styles/about.css'
import TranslatHook from '../hook/translat-hook'
import HomeCareAbout from "../Components/Home/homeCareAbout";

const AboutPage = () => {
    const [lang] = TranslatHook();

    return (
        <>
            <div style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                <AboutPageHeader />
                <Aboutus />
                <HomeCareAbout />

                <Aboutus2 />
            </div>
        </>

    )
}

export default AboutPage