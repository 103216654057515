import React from "react";
import TranslatHook from "../../hook/translat-hook";
const homeReachUS = () => {
  const [lang, content] = TranslatHook();
  return (
    <>
      <section id="ReachUS">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 bag-loc-is position-relative">
              <div className="overlay-gazi position-absolute top-0 start-0"></div>
              <h2
                style={{
                  textAlign: "start",
                  zIndex: 999,
                  position: "relative",
                  color: "#fff",
                }}
              >
                {content.Istanbul}
              </h2>
              <p
                style={{
                  textAlign: "start",
                  zIndex: 1,
                  position: "relative",
                  color: "#fff",
                }}
              >
                MAHMUT BEY HACI BOSTAN CAD. NO:22/A BAĞCILAR İSTANBUL
              </p>
              <a
                href="https://www.google.com/maps/place/Mahmutbey,+Hac%C4%B1+Bostan+Cd.+No:22+D:a,+34218+Ba%C4%9Fc%C4%B1lar%2F%C4%B0stanbul/@41.0592996,28.8189679,17z/data=!3m1!4b1!4m5!3m4!1s0x14caa573ca6f676b:0x3ce2e6f7a77f7c12!8m2!3d41.0592956!4d28.8215482?entry=ttu"
                target="_blank"
              >
                <i
                  className="fa-solid fa-circle-right fa-fade"
                  style={{
                    float: lang === "ar" ? "left" : "right",
                    rotate: lang === "ar" ? "180deg" : "",
                    position: "relative",
                    color: "#fff",
                  }}
                ></i>
              </a>
            </div>

            <div className="col-lg-12 col-12 bag-loc position-relative">
              <div className="overlay-gazi position-absolute top-0 start-0"></div>
              <h2
                style={{
                  textAlign: "start",
                  zIndex: 100,
                  position: "relative",
                }}
              >
                {content.Gaziantep}
              </h2>
              <p
                style={{ textAlign: "start", zIndex: 1, position: "relative" }}
              >
                İNCİLİPINAR MAH. PRF. MUAMMER AKSOY BLV. NO:26/C ŞEHİTKMİL
                GAZİANTEP
              </p>
              <a
                href="https://www.google.com/maps/place/NOONTEK/@37.0689102,37.3710432,3a,75y,9.5h,91.73t/data=!3m6!1e1!3m4!1sg3Jg5_FmY7jE3x6L-uotzA!2e0!7i16384!8i8192!4m15!1m8!3m7!1s0x1531e1457881f74f:0x1ae67ba31304e2aa!2s%C4%B0ncili+P%C4%B1nar,+Prof.+Muammer+Aksoy+Blv.+No:26+D:c,+27090+%C5%9Eehitkamil%2FGaziantep!3b1!8m2!3d37.0690061!4d37.3714013!10e5!3m5!1s0x1531e144fad323d7:0x16f0afaee865e785!8m2!3d37.0690263!4d37.3709226!16s%2Fg%2F11b7qbwm9l?entry=ttu"
                target="_blank"
              >
                <i
                  className="fa-solid fa-circle-right fa-fade position-absolute "
                  style={{
                    color: "#fff",
                    float: lang === "ar" ? "right" : "right",
                    transform:
                      lang === "ar" ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default homeReachUS;
