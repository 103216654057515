import React from 'react'
import RatePost from './ratePost'
import SelectDepartment from './selectDepartment'
import TranslatHook from '../../hook/translat-hook'

const RataServes = () => {
    const [lang, content] = TranslatHook()
    return (
        <div>
            <SelectDepartment />
            <div className="col-md-12 col-sm-6 test ">
                <p className="col-md-6 col-sm-3 d-inline textRate" >{content.rateTheservice}</p>
                <div className="col-md-6 col-sm-1  ">
                </div>
            </div>
            <div className="col-md-12 col-sm-3 d-flex serviceRate ">
                <div className="col-md-6 col-sm-3 d-inline serviceRate1">
                    <button type="button" className="contact_btn button gradient-btn submit_btn1 service" name='service' value='employee'>{content.employee}</button>
                    <button type="button" className="contact_btn button gradient-btn submit_btn1 service" name='service' value='price'>{content.price}</button>
                    <button type="button" className="contact_btn button gradient-btn submit_btn1 service" name='service' value='Speed'>{content.speed}</button>
                    <button type="button" className="contact_btn button gradient-btn submit_btn1 service" name='service' value='Quality'>{content.quality}</button>
                    <input type='text' name='EmployeeName' value={content.employee} hidden />
                    <input type='text' name='Price' value={content.price} hidden />
                    <input type='text' name='SpeedName' value={content.speed} hidden />
                    <input type='text' name='QualityName' value={content.quality} hidden />
                </div>
                <div className={`cl-md-6 col-sm-3  star ${lang === 'ar' ? 'starAr' : 'starEn'} `}  >
                    <RatePost service='employeeRate' />
                    <RatePost service='PriceRate' />
                    <RatePost service='SpeedRate' />
                    <RatePost service='QualityRate' />
                   
                </div>
            </div>
        </div>
    )
}

export default RataServes