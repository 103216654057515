import React from "react";
import imgfooter from "../../images/tek.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <>
      <footer id="site-footer" className=" bgprimary padding_top">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-8 col-sm-3 col-xs-6 col-6 footer-image-center">
              <div className="footer_panel  ">
                <a href="index.php" className="footer_logo bottom25">
                  <img
                    src={imgfooter}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    alt="MegaOne"
                    className="footer_logo"
                  />
                </a>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer_panel padding_bottom_half bottom20 pl-0 pl-lg-5">
                <h3 className="whitecolor bottom25">Our Policy</h3>
                {/* <ul className="links">
                  <li>
                    <a href="">Terms & conditions</a>
                  </li>
                  <li>
                    <a href="">Privacy and Payment Policy</a>
                  </li>
                  <li>
                    <a href="">Cookie Policy</a>
                  </li>
                  <li>
                    <a href="">Kvkk</a>
                  </li>
                </ul> 
              </div>
            </div> */}
            <div
              className="col-lg-5 col-md-3 col-sm-6 col-xs-6  col-6 footer-image-center"
              style={{
                float: "right",
                justifyContent: "right",
                display: "flex",
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <div
                className="footer_panel  "
                style={{ fontFamily: "Montserrat, sans-serif" }}
              >
                {/* <ul className="links">
                  <li>
                    <a href="https://smartinb.com/">Smart HR</a>
                  </li>
                  <li>
                    <a href="https://www.noonmar.com/en">Noonmar</a>
                  </li>
                </ul> */}
                <div className="d-table w-30 address-item whitecolor">
                  <p
                    className="d-table-cell align-middle bottom0"
                    style={{
                      float: "right",
                      direction: "ltr",
                    }}
                  >
                    <i className="fa-solid fa-mobile-screen-button fa-xl"></i>
                    <a href="tel:00908502230069"> +90 850 223 00 69 </a>

                    <a
                      className="d-block"
                      href="mailto:info@noontek.com"
                      style={{ margin: "10px 0 0 0" }}
                    >
                      <i
                        className="fa-regular fa-envelope  fa-xl"
                        style={{ marginRight: "5px" }}
                      ></i>
                      info@noontek.com
                    </a>
                  </p>
                </div>
                {/* <p>facebook</p>
                <p>twitter </p>
                <p>linkedin</p>
                <p>instagram</p> */}
              </div>
            </div>
          </div>

          <ul
            className="social-icons white wow fadeInUp  "
            data-wow-delay="300ms"
          >
            <li>
              <a
                href="https://www.facebook.com/noontek.tr/"
                className="facebook "
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebookF} className="iconswhite" />
              </a>{" "}
            </li>
            <li>
              <a
                href="https://twitter.com/noontek"
                className="twitter"
                target="_blank"
              >
                <FontAwesomeIcon icon={faTwitter} />{" "}
              </a>{" "}
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/noontektr/"
                className="linkedin"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  className="svg-inline--fa fa-linkedin-in"
                />
              </a>{" "}
            </li>
            <li>
              <a
                href="https://www.instagram.com/noontek.co/?hl=en"
                className="insta"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>{" "}
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
