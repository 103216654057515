import React from 'react'
import HomeMainSlider from '../Components/Home/homeMainSlider'
import Partners from '../Components/Uitily/Partners'
import HomeAboutUs from '../Components/Home/homeAboutUs'
import HomeOurSercices from '../Components/Home/homeOurSercices'
import HomeCareAbout from '../Components/Home/homeCareAbout'
import HomeStatics from '../Components/Home/homeStatics'
import HomeTestimonials from '../Components/Home/homeTestimonials'
import HomeReachUS from '../Components/Home/homeReachUS'
import TranslatHook from '../hook/translat-hook'

const HomePage = () => {
    const [lang] = TranslatHook();

    return (
        <>

            <HomeMainSlider />
            <Partners />
            <HomeAboutUs />
            <div style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
               
                <HomeOurSercices />
                <HomeCareAbout />
                <HomeStatics />
                <HomeTestimonials />
                <HomeReachUS />
            </div>
        </>

    )
}

export default HomePage