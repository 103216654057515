import React from "react";
import img1 from "../../images/img/systems.jpeg";
import img2 from "../../images/img/Maintenance.jpeg";
import img3 from "../../images/img/e-comars.jpeg";
import img4 from "../../images/img/Enterprisesales.jpeg";
import TranslatHook from "../../hook/translat-hook";
import { Link } from "react-router-dom";
const homeOurSercices = () => {
  const [, content] = TranslatHook();
  return (
    <>
      <section id="services">
        <h1 className="mainH">{content.OUR_SERVICES}</h1>
        <div className="MyServices">
          <div className="service-item">
            <div className="content">
              <b target="_blank">
                <Link to="/oursvices/2">
                  <div className="content-overlay"></div>
                  <img className="content-image" src={img1} alt="" />
                  <div className="content-details fadeIn-left">
                    <h3>{content.security}</h3>
                    <p>{content.ProtectionSystems}</p>
                  </div>
                </Link>
              </b>
            </div>
          </div>
          <div className="service-item">
            <div className="content">
              <b target="_blank">
                <Link to="/oursvices/3">
                  <div className="content-overlay"></div>
                  <img className="content-image" src={img2} alt="" />
                  <div className="content-details fadeIn-left">
                    <h3>{content.maintenance}</h3>
                    <p>{content.Maintenance}</p>
                  </div>
                </Link>
              </b>
            </div>
          </div>
          <div className="service-item">
            <div className="content">
              <b target="_blank">
                <Link to="/oursvices/1">
                  <div className="content-overlay"></div>
                  <img className="content-image" src={img3} alt="" />
                  <div className="content-details fadeIn-left">
                    <h3>{content.ecommerce}</h3>
                    <p>{content.ECommerce}</p>
                  </div>
                </Link>
              </b>
            </div>
          </div>
          <div className="service-item">
            <div className="content">
              <b target="_blank">
                <Link to="/oursvices/0">
                  <div className="content-overlay"></div>
                  <img className="content-image" src={img4} alt="" />
                  <div className="content-details fadeIn-left">
                    <h3>{content.institutions}</h3>
                    <p>{content.EnterpriseSales}</p>
                  </div>
                </Link>
              </b>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default homeOurSercices;
