import React from "react";
import dataa from "../../../dataa";
import { useParams } from "react-router-dom";
import img from "../../../images/services.jpg";
import OurServeicesPage from "./OurServeicesPage";
import TranslatHook from "../../../hook/translat-hook";

const HederOurServeices = () => {
  const { id } = useParams();
  const [dataServes] = dataa();
  const [lang] = TranslatHook();

  return (
    <>
      <section
        id="main-banner-page"
        className="position-relative page-header contact-header section-nav-smooth parallax"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center 0px",
        }}
      >
        <div
          className="container"
          style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
        >
          <div className="row">
            <div>
              <div className="page-titles whitecolor text-center padding">
                <h2 className="font-bold">
                  {" "}
                  <div key={id}>{dataServes[id].name}</div>
                </h2>
              </div>
            </div>
          </div>
          <div className="overlay overlay-dark opacity-6 z-index-1"></div>

          <div className="title-wrap header-wrap">
            <div className="row">
              <div className="col-lg-12 col-md-12 whitecolor">
                <h3 className="float-left" style={{ float: "right" }}>
                  خدماتنا{" "}
                </h3>
                <ul
                  className="breadcrumb top10 bottom10 float-right"
                  style={{ float: "right" }}
                >
                  {/* <li className="breadcrumb-item hover-light">
             
                </li>
                <li className="breadcrumb-item hover-light">OUR SERVICES</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OurServeicesPage />
    </>
  );
};

export default HederOurServeices;
