import TranslatHook from "./hook/translat-hook";
import selImage from "./images/img/inSide.jpeg";
import mhImage from "./images/img/t.jpg";
import dataImage from "./images/img/keb.jpeg";
import supImage from "./images/img/services/Maintenance/Maintenance1.jpeg";
import suplayerImage from "./images/img/support.jpeg";
import ElecImage from "./images/img/services/backup/backup.jpeg";

//image seleig start
import seleigImage1 from "./images/img/services/Sell/sell1.jpeg";
import seleigImage2 from "./images/img/services/Sell/sell2.jpeg";
import seleigImage3 from "./images/img/services/Sell/sell3.jpeg";
import seleigImage4 from "./images/img/services/Sell/sell4.jpeg";

//image seleig End

//image MH start
import MHImage1 from "./images/img/services/copm/comp1.jpeg";
import MHImage2 from "./images/img/services/copm/comp2.jpeg";
import MHImage3 from "./images/img/services/copm/comp3.jpeg";

//image MH End

//image data start
import dataImage1 from "./images/img/services/camera/camer1.jpeg";
import dataImage2 from "./images/img/services/camera/camer2.jpeg";
import dataImage3 from "./images/img/services/camera/camer3.jpeg";

//image data End

//image sup start
import supImage1 from "./images/img/services/Maintenance/Maintenance1.jpeg";
import supImage2 from "./images/img/services/Maintenance/Maintenance2.jpeg";
import supImage3 from "./images/img/services/Maintenance/Maintenance3.jpeg";
import supImage4 from "./images/img/services/Maintenance/Maintenance4.jpeg";
//image sup End

//image suplayer start
import suplayerImage1 from "./images/img/services/Support/Support1.jpeg";
import suplayerImage2 from "./images/img/services/Support/Support2.jpeg";
import suplayerImage3 from "./images/img/services/Support/Support3.jpeg";

//image suplayer End

//image Ka start
import ElecImage1 from "./images/img/services/backup/backup.jpeg";
import ElecImage2 from "./images/img/services/backup/backup1.jpeg";
import ElecImage3 from "./images/img/services/backup/backup2.jpeg";
import ElecImage4 from "./images/img/services/backup/backup3.jpeg";
import ElecImage5 from "./images/img/services/backup/backup5.jpeg";
//image ka End

const dataa = () => {
  const [, content] = TranslatHook();

  const dataServes = [
    {
      id: 0,
      name: content.sellingName,
      title: content.selling,
      user: "محمد حمدان ",
      phone: "+90 537 065 57 11",
      image: selImage,
      email:"info@noontek.com"
    },
    {
      id: 1,
      name: content.EngineeringOfficeName,
      title: content.EngineeringOffice,
      user: "أنس جعو",
      phone: "+90 534 443 90 90",
      image: mhImage,
      email:"sales@noontek.com"

    },
    {
      id: 2,
      name: content.DataSecurityAndRecoveryName,
      title: content.DataSecurity,
      user: "أسامة دهنين",
      phone: "+90 535 040 90 85      ",
      image: dataImage,
      email:"osama.dahnin@noontek.com"

    },
    {
      id: 3,
      name: content.technicalSupportName,
      title: content.technicalSupport,
      title1: content.technicalSupport1,
      user: "محمد عوض",
      phone: "+90 850 223 00 69",
      image: supImage,
      email:"info@noontek.com"

    },
    {
      id: 4,
      name: content.SupplyServicesForEnterprisesName,
      title: content.SupplyServicesForEnterprises1,
      image: suplayerImage,
      user: "محمد عوض",
      phone: "+90 850 223 00 69",
      email:"info@noontek.com"

    },
    {
      id: 5,
      name: content.ElectronicDeviceRenewalServiceName,
      title: content.ElectronicDeviceRenewalService,
      image: ElecImage,
      user: "محمد جزماتي",
      phone: "+90 537 442 67 48",
      email:"info@noontek.com"

    },
  ];
  const images = [
    {
      id: 0,
      image: [seleigImage1, seleigImage2, seleigImage3, seleigImage4],
    },
    { id: 1, image: [MHImage1, MHImage2, MHImage3] },
    {
      id: 2,
      image: [dataImage1, dataImage2, dataImage3],
    },
    { id: 3, image: [supImage1, supImage2, supImage3, supImage4] },
    {
      id: 4,
      image: [suplayerImage1, suplayerImage2, suplayerImage3],
    },
    {
      id: 5,
      image: [ElecImage1, ElecImage2, ElecImage3, ElecImage4, ElecImage5],
    },
  ];

  return [dataServes, images];
};
export default dataa;
