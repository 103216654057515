import React from "react";
import LeftSideComponentOurServeice from "./LeftSideComponentOurServeice";
import TextCopmnentsOurServices from "./TextCopmnentsOurServices";
import TranslatHook from "../../../hook/translat-hook";

const OurServeicesPage = () => {
  const [lang] = TranslatHook();

  return (
    <div>
      <section
        id="our-services"
        className="padding_top padding_bottom_half bglight"
      >
        <div className="container">
          <div
            className="row whitebox top15"
            style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
          >
            <LeftSideComponentOurServeice />
            <TextCopmnentsOurServices />
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurServeicesPage;
