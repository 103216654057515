import { faAngleUp, faArrowUp, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
const But = () => {
    useEffect(() => {
        const handleScroll = () => {
            const backToTopButton = document.getElementById('back-to-top');
            if (window.scrollY > 250) {
                backToTopButton.classList.add('show');
            } else {
                backToTopButton.classList.remove('show');
            }
        };

        const handleScrollToTop = (e) => {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        window.addEventListener('scroll', handleScroll);
        document
            .getElementById('back-to-top')
            .addEventListener('click', handleScrollToTop);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document
                .getElementById('back-to-top')
                .removeEventListener('click', handleScrollToTop);
        };
    }, []);

    return (
        <a href="#top" id="back-to-top" >
            <FontAwesomeIcon icon={faAngleUp} style={{ width: '100%', height: '100%', }} />
        </a>
    );
};

export default But;
