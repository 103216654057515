import React from 'react'
import TransNavbarHook from '../../hook/trans-navbar-hook'
import TranslatHook from '../../hook/translat-hook';

const TransMobileTrans = () => {
    const [lang, content] = TranslatHook();

    const [options, , , , selectedOption, , handleLanguageChange, ,] = TransNavbarHook()

    return (
        <>
            <select className='select' onChange={(e) => handleLanguageChange(options.find((option) => option.value === e.target.value))} >

                <option hidden >{content.language}</option>
                {options.map((option) => (
                    <option className='selectbut' key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}

            </select>

        </>

    )
}

export default TransMobileTrans