import React from "react";
import img from "../../images/Noon/Team1.jpeg";
import TranslatHook from "../../hook/translat-hook";
const AboutUs = () => {
  const [lang, content] = TranslatHook();

  return (
    <section id="aboutus" className="padding_top padding_bottom">
      <div className="container aboutus">
        <div className="row align-items-center aboutus1">
          <div className="col-lg-5 col-md-5 padding_bottom_half">
            <div className="image">
              <img alt="SEO" src={img} />
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 col-md-6 padding_bottom_half text-md-left">
            <h2 className="darkcolor font-normal bottom30">
              {content.aboutusNoontek}
            </h2>
            <p className="bottom35" style={{fontSize:"18px"}}>{content.aboutus}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
