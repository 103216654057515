import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Container } from "react-bootstrap";
import "../../../styles/Partners.css";
import dataa from "../../../dataa";
import { useParams } from "react-router-dom";

const Partners = () => {
  const [slidesToShow, setSlidesToShow] = useState(4);
  const { id } = useParams();
  const [, images] = dataa();
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 640 && windowWidth > 550) {
        setSlidesToShow(3);
      } else if (windowWidth <= 550) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2500,
  };
  console.log(images);
  return (
    <div className="mainContainer" id="our-partnerss">
      <Container style={{ padding: "2rem !important" }}>
        <Slider {...settings} className="slider-1">
          {images[id].image.map((item, index) => {
            return (
              <div
                className="container HelloMerhaba "
                style={{ objectFit: "cover" }}
                key={index}
              >
                <img
                  className=""
                  alt=""
                  src={item}
                  style={{ maxWidth: "90%" }}
                />
              </div>
            );
          })}
        </Slider>
      </Container>
    </div>
  );
};

export default Partners;
