import React from "react";
import RatingHeader from "../Components/Rating/ratingHeader";
import ReatingForm from "../Components/Rating/reatingForm";
import TranslatHook from "../hook/translat-hook";

const RatingPage = () => {
  const [lang] = TranslatHook();

  return (
    <>
      <div style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
        <RatingHeader />

        <ReatingForm />
      </div>
    </>
  );
};

export default RatingPage;
