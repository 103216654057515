/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import TranslatHook from "../../hook/translat-hook";
import { useDispatch, useSelector } from "react-redux";
import { setLanguae } from "../../rudex/actions/actions";
import NavbarHook from "../../hook/navbar-hook";
import TransMobileTrans from "../test/TransMobileTrans";
import log1 from "../../images/client-1.png";
import log2 from "../../images/tek.029da2b40d061e2e1913.png";

const Navbar = () => {
  const [lang, content] = TranslatHook();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsSticky(scrollTop > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const options = [
    { value: "en", label: content.English },
    { value: "tr", label: content.Türkçe },
    { value: "ar", label: content.Arabic },
  ];
  const dispatch = useDispatch();
  const lang1 = useSelector((state) => state.lang);
  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option.value === lang1)
  );
  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleLanguageChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    dispatch(setLanguae(selectedOption.value));
    togoup();
  };
  const togoup = () => {
    window.scrollTo({ top: 0 });
  };

  const handleMouseEnter = () => {
    setMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setMenuOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false);

  const navbarOpen = () => {
    setIsOpen(!isOpen);
  };
  const navbargotoUp = () => {
    window.scrollTo({ top: 0 });
    setIsOpen(!isOpen);
  };
  return (
    <>
      <header
        className={`site-header  ${isSticky ? "fixed-top" : ""}`}
        style={{ color: isSticky ? "#000" : "" }}
        id="header"
      >
        <nav
          className={`navbar navbar-expand-lg transparent-bg static-nav d-flex justify-content-between`}
          style={{ backgroundColor: isSticky ? "rgba(255,255, 255, 0.9)" : "" }}
        >
          <div className={`container-fluid container-xl  `}>
            <div className="d-flex col-md-4 align-items-center justify-content-start">
              <Link
                to="/"
                className={`navbar-brand ${isSticky ? "scrolled" : ""}`}
              >
                {!isSticky && (
                  <img style={{ width: "175%" }} src={log1} alt="logo" />
                )}
                {isSticky && (
                  <>
                    <img style={{ width: "175%" }} src={log2} alt="logo" />
                  </>
                )}
              </Link>
            </div>
            <div className=" col-md-8  ">
              {window.innerWidth >= 800 && (
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link
                      className="nav-link slowlymove"
                      to="/"
                      style={{ color: isSticky ? "#000" : "" }}
                      onClick={togoup}
                    >
                      {content.Home}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link slowlymove"
                      to="/about"
                      style={{ color: isSticky ? "#000" : "" }}
                      onClick={togoup}
                    >
                      {content.About}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link slowlymove"
                      to="/services"
                      style={{ color: isSticky ? "#000" : "" }}
                      onClick={togoup}
                    >
                      {content.OUR_SERVICES}
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      className="nav-link slowlymove"
                      to="/team"
                      style={{ color: isSticky ? "#000" : "" }}
                      onClick={togoup}
                    >
                      {content.OUR_TEAM}
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      className="nav-link slowlymove"
                      to="/contact"
                      style={{ color: isSticky ? "#000" : "" }}
                      onClick={togoup}
                    >
                      {content.contact}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link slowlymove"
                      to="/rating"
                      style={{ color: isSticky ? "#000" : "" }}
                      onClick={togoup}
                    >
                      {content.Rating}
                    </Link>
                  </li>
                  {/* <li
                    className={`nav-item dropdown position-relative ${
                      isMenuOpen ? "open" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <a
                      className="nav-link "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ color: isSticky ? "#000" : "" }}
                    >
                      {content.language}
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ marginLeft: "7px", marginRight: "7px" }}
                      />
                    </a>
                    {/* <div className="dropdown-menu">
                    {options.map((option) => (
                      <div key={option.value}>
                        <a
                          className="dropdown-item language"
                          onClick={() => handleLanguageChange(option)}
                        >
                          {option.label}
                        </a>
                      </div>
                    ))}
                  </div> 
                  </li> */}
                </ul>
              )}
            </div>
          </div>
          <a
            href="javascript:void(0)"
            className="d-inline-block sidemenu_btn"
            id="sidemenu_toggle"
            style={{
              left: lang === "ar" ? "20px" : "",
              right: lang === "ar" ? "" : "20px",
            }}
            onClick={navbarOpen}
          >
            <span style={{ backgroundColor: "#379397" }}></span>{" "}
            <span style={{ backgroundColor: "#379397" }}></span>{" "}
            <span style={{ backgroundColor: "#379397" }}></span>
          </a>
        </nav>

        <div
          className={` side-menu bg-yellow ${isOpen ? `side-menu-active` : ``}`}
        >
          <div className="overlay "></div>
          <div className="inner-wrapper">
            <span
              className="btn-close"
              onClick={navbarOpen}
              id="btn_sideNavClose"
            >
              <i></i>
              <i></i>
            </span>
            <nav className="side-nav w-100">
              <ul
                className="navbar-nav"
                style={{ alignItems: lang === "ar" ? "start" : "rtl" }}
              >
                <li className="nav-item">
                  <Link
                    className="nav-link slowlymove"
                    to="/"
                    style={{ color: isSticky ? "#000" : "" }}
                    onClick={navbargotoUp}
                  >
                    {content.Home}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link slowlymove"
                    to="/about"
                    style={{ color: isSticky ? "#000" : "" }}
                    onClick={navbargotoUp}
                  >
                    {content.About}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link slowlymove"
                    to="/services"
                    style={{ color: isSticky ? "#000" : "" }}
                    onClick={navbargotoUp}
                  >
                    {content.OUR_SERVICES}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link slowlymove"
                    to="/team"
                    style={{ color: isSticky ? "#000" : "" }}
                    onClick={navbargotoUp}
                  >
                    {content.OUR_TEAM}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link slowlymove"
                    to="/contact"
                    style={{ color: isSticky ? "#000" : "" }}
                    onClick={navbargotoUp}
                  >
                    {content.contact}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link slowlymove"
                    to="/rating"
                    style={{ color: isSticky ? "#000" : "" }}
                    onClick={navbargotoUp}
                  >
                    {content.Rating}
                  </Link>
                </li>
                <li className="nav-item">
                  <TransMobileTrans />
                </li>
              </ul>
            </nav>
            <div className="side-footer w-100">
              <ul className="social-icons-simple white top40">
                <li>
                  <a href="javascript:void(0)" className="facebook">
                    <i className="fab fa-facebook-f"></i>{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="javascript:void(0)" className="twitter">
                    <i className="fab fa-twitter"></i>{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="javascript:void(0)" className="insta">
                    <i className="fab fa-instagram"></i>{" "}
                  </a>{" "}
                </li>
              </ul>
              <p className="whitecolor">
                © 2019 MegaOne. Made With Love by ThemesIndustry
              </p>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
