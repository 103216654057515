import React, { useState, useEffect } from "react";

const CountNumber = ({ to, speed }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const duration = Math.abs(speed);
    const increment = to > count ? 1 : -1;
    const stepTime = Math.abs(Math.floor(duration / (to - count)));
    if (count !== to) {
      const timer = setInterval(() => {
        setCount((prevCount) => prevCount + increment);
      }, stepTime);
      return () => {
        clearInterval(timer);
      };
    }
  });

  return <span className="count_nums font-light">{count}</span>;
};

export default CountNumber;
