import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import '@fortawesome/fontawesome-free/css/all.min.css';

const RatePost = ({ service }) => {
    const [value, setValue] = useState(3);

    const setting = {
        size: 20,
        count: 5,
        color: 'rgba(37,93,97,0.5)',
        activeColor: "#fea219",
        value: value,
        a11y: true,
        isHalf: true,
        emptyIcon: <i className="far fa-star" />,
        halfIcon: <i className="fa fa-star-half-alt" />,
        filledIcon: <i className="fa fa-star" />,
        onChange: (newValue) => {
            setValue(newValue);
        },

    };

    return (
        <>
            <p>
                <ReactStars {...setting} />
                <input hidden type='text' name={service} value={value} />

            </p>
        </>
    );
};

export default RatePost;
