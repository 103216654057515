import React from "react";
import { Link } from "react-router-dom";
import img from "../../images/img/Rating.jpeg";
import TranslatHook from "../../hook/translat-hook";
const RatingHeader = () => {
  const [lang, content] = TranslatHook();
  return (
    <section
      id="main-banner-page"
      className="position-relative page-header contact-header section-nav-smooth parallax"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center 0px",
      }}
    >
      <div className="container">
        <div className="row">
          <div>
            <div className="page-titles whitecolor text-center padding">
              <h2
                className="font-bold"
                style={{ textAlign: "center", justifyContent: "center" }}
              >
                {" "}
                قيمنا{" "}
              </h2>
              <h3 className="font-light py-2"></h3>
            </div>
          </div>
          <div className="overlay overlay-dark opacity-6 z-index-1"></div>
        </div>
        <div className="title-wrap header-wrap">
          <div className="row">
            <div className="col-lg-12 col-md-12 whitecolor">
              <h3
                className="float-left"
                style={{ float: lang === "ar" ? "right " : "left" }}
              >
                {content.Rating}
              </h3>
              <ul
                className="breadcrumb top10 bottom10"
                style={{ float: lang === "ar" ? "left" : "right" }}
              >
                <li className=" hover-light">
                  <Link to="/">{content.Home}</Link>
                </li>
                {/* <li className=" hover-light" style={{ margin: '0 5px' }}>/</li>*/}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RatingHeader;
