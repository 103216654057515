import React from "react";
import img from "../../images/img/About.jpeg";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import TranslatHook from "../../hook/translat-hook";
const homeAboutUs = () => {
  const [lang, content] = TranslatHook();
  return (
    <section id="why-us" className="why-us">
      <Container data-aos="fade-up">
        <div className="mainH" style={{ textAlign: "center" }}>
          <h2>{content.AboutUs}</h2>
        </div>

        <Row className=" g-0" data-aos="fade-up" data-aos-delay="200">
          <Col xl={7} className="position-relative">
            <Swiper
              spaceBetween={100}
              slidesPerView={1}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              // autoplay={{
              //   delay: 25000,
              //   disableOnInteraction: false,
              // }}
              loop={true}
              pagination={{ clickable: true }}
              modules={[Autoplay, Pagination, Navigation]}
            >
              <SwiperSlide>
                <div
                  className="item "
                  style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
                >
                  <h3>{content.WhoWeAre}</h3>
                  {/* <h4>{content.WhoWeAre}</h4> */}

                  <p style={{ fontSize: "20px" }}>
                    {" "}
                    {content.whoisnoontek}
                    <br />
                    <br /> {content.Foundednoontek}
                    <br />
                    <br />
                    {content.companyresource}
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="item "
                  style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
                >
                  <h3>{content.Ourmission}:</h3>
                  {/* <h4>{content.WhatisJudy}</h4> */}

                  <p style={{ fontSize: "20px" }}>{content.OurmissionDes}</p>
                  <br />
                  <h3>{content.Ourvision}:</h3>
                  <p style={{ fontSize: "20px" }}>{content.OurvisiondDes}</p>
                </div>
              </SwiperSlide>

              <div className="swiper-pagination"></div>

              {/* <div className="swiper-scrollbar"></div> */}
            </Swiper>

            <div className="swiper-button-next swiper-button-white"></div>
            <div className="swiper-button-prev swiper-button-white"></div>
          </Col>
          <Col
            xl={5}
            className=" img-bg"
            style={{ backgroundImage: `url(${img})` }}
          ></Col>
        </Row>
      </Container>
    </section>
  );
};

export default homeAboutUs;
