import  { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SetContent } from '../rudex/actions/actions';
import data from '../data.json'
const TranslatHook = () => {

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang);
    const content = useSelector((state) => state.content);

    useEffect(() => {
        if (lang === 'en') {
            dispatch(SetContent(data.en))
        }
        else if (lang === 'tr') {
            dispatch(SetContent(data.tr))
        }
        else if (lang === 'ar') {
            dispatch(SetContent(data.ar))
        }
    }, [lang, dispatch])

    return [lang, content]
}

export default TranslatHook