import React from "react";
import TranslatHook from "../../hook/translat-hook";
import img2 from "../../images/Noon/Team22.jpeg";

const aboutus2 = () => {
  const [lang, content] = TranslatHook();

  const backgroundImageStyle = {
    backgroundImage: `url(${img2})`, // Use `url()` to specify the image URL
  };

  return (
    <section id="aboutus2">
      <div className="container">
        <div className="about-us2 row">
          <div
            className="about-image col-lg-5 col-4"
            style={backgroundImageStyle}
          ></div>
          <div className="about-content col-lg-6 col-8">
            <h3>{content.Ourmission}</h3>
            <p style={{ marginTop: "0px", fontSize: "17.5px" }}>
              {content.OurmissionDes}
            </p>
            <h3 style={{ marginTop: "0px" }}>{content.Ourvision}</h3>
            <p style={{ marginTop: "-20px", fontSize: "17.5px" }}>
              {content.OurvisiondDes}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default aboutus2;
