import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TranslatHook from "../../hook/translat-hook";

const ContactUs = () => {
  const [lang] = TranslatHook();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dy63msd",
        "template_o5ejodq",
        form.current,
        "3L4L09G1GaAt7wKUX"
      )
      .then(
        (result) => {
          console.log("OK");
        },
        (error) => {
          console.log("ERROR" + error);
        }
      );

    form.current.reset(); // Call reset on the form element
  };

  return (
    <section
      id="stayconnect1"
      className="bglight position-relative padding_top padding_bottom_half noshadow"
      style={{ direction: lang === "ar" ? "ltr" : "ltr" }}
    >
      <div
        className="container whitebox"
        style={{ width: "80%", backgroundColor: "#fff", borderRadius: "20px" }}
      >
        <div className=" py-5">
          <div className="row">
            <div className="col-md-4 col-sm-4 order-sm-2 love">
              <div className="contact-meta px-2 text-center  text-md-left ">
                <div className="heading-title heading_small bottom50 size">
                  <span className=" mb-3"></span>
                  <h3 className=" font-normal mb-4">نحن نحب أن نسمع منك </h3>
                </div>
                <p className="bottom20" style={{ display: "flex" }}>
                  <span
                    style={{
                      backgroundColor: "#379397",
                      borderRadius: "5px",
                      width: "30px",
                      height: "25px",
                      marginRight: "10px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <i className="fa-regular fa-envelope"></i>{" "}
                  </span>
                  <a href="mailto:info@noontek.com">info@noontek.com</a>
                </p>
                <p className="bottom20" style={{ display: "flex" }}>
                  <span
                    style={{
                      backgroundColor: "#379397",
                      borderRadius: "5px",
                      width: "30px",
                      height: "25px",
                      marginRight: "10px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <i className="fa-solid fa-phone"></i>{" "}
                  </span>
                  <a href="tel:0850 223 00 69">0850 223 00 69</a>
                </p>
                <p className="bottom20" style={{ display: "flex" }}>
                  <span
                    style={{
                      backgroundColor: "#379397",
                      borderRadius: "5px",
                      width: "30px",
                      height: "25px",
                      marginRight: "10px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <i className="fa-regular fa-clock"></i>{" "}
                  </span>
                  From 8:30 To 6:00{" "}
                </p>
                <p
                  className="bottom20"
                  style={{ display: "flex", textAlign: "left" }}
                >
                  <span
                    style={{
                      backgroundColor: "#379397",
                      borderRadius: "5px",
                      width: "35px",
                      height: "25px",
                      alignItems: "center",
                      marginRight: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <i className="fa-solid fa-map-location-dot"></i>{" "}
                  </span>
                  MAHMUT BEY HACI BOSTAN CAD.NO:22/A BAĞCILAR İSTANBUL{" "}
                </p>
              </div>
            </div>
            <div className="col-md-7 col-sm-7 ">
              <h4
                style={{
                  color: "#1d6366",
                  float: "right",
                  marginBottom: "100px",
                }}
                className="connactus"
              >
                <b style={{ fontSize: "24px" }}>تواصل معنا</b> لمزيد من
                المعلومات
              </h4>
              <div
                className="heading-title  wow fadeInUp"
                data-wow-delay="300ms"
              >
                <Form ref={form} onSubmit={sendEmail}>
                  <Form.Group
                    style={{
                      display: "flex",
                      direction: "rtl",
                      float: "right",
                    }}
                    className="col-md-12 col-sm-5 mb-3"
                    name="name"
                    id="name"
                  >
                    <Form.Label></Form.Label>
                    <Form.Control
                      style={{ marginLeft: "30px" }}
                      type="text"
                      placeholder="الاسم"
                      id="name"
                      name="name"
                    />
                    <Form.Control
                      type="text"
                      placeholder="رقم الهاتف "
                      id="name"
                      name="name"
                    />
                  </Form.Group>

                  <Form.Group
                    style={{
                      display: "flex",
                      direction: "rtl",
                      float: "right",
                    }}
                    className="col-md-12 col-sm-5 mb-3"
                    name="name"
                    id="name"
                  >
                    <Form.Label></Form.Label>
                    <Form.Control
                      style={{ marginLeft: "30px" }}
                      type="text"
                      placeholder="بريد الكتروني"
                      id="name"
                      name="name"
                    />
                    <Form.Control
                      type="text"
                      placeholder="سبب"
                      id="re"
                      name="re"
                    />
                  </Form.Group>

                  <Form.Group
                    id=""
                    className="mb-3"
                    style={{
                      direction: "rtl",
                    }}
                  >
                    <Form.Control
                      placeholder="الرسالة"
                      name="message"
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>
                  <div className="col-md-12 col-sm-12">
                    <button
                      style={{ float: "right" }}
                      type="submit"
                      id="submit_btn1"
                      className="contact_btn button gradient-btn "
                    >
                      ارسل
                    </button>
                  </div>
                </Form>
                <div
                  className="container"
                  style={{
                    borderTop: " 1px solid #379397",
                    marginTop: "200px",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-md-12 col-sm-12"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        marginTop: "30px",
                      }}
                    >
                      <ul
                        className="social-icons white wow fadeInUp "
                        data-wow-delay="300ms"
                      >
                        <li>
                          <a
                            href="https://www.facebook.com/noontek.tr/"
                            className="facebook "
                            target="_blank"
                            style={{
                              backgroundColor: "#124345",
                              color: "#fff",
                              width: "31px",
                              borderRadius: "5px",
                              marginLeft: "20px",

                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faFacebookF}
                              className="iconswhite"
                            />
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/noontek"
                            className="twitter"
                            target="_blank"
                            style={{
                              backgroundColor: "#124345",
                              color: "#fff",
                              width: "31px",
                              borderRadius: "5px",
                              marginLeft: "20px",

                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            }}
                          >
                            <FontAwesomeIcon icon={faTwitter} />{" "}
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/noontektr/"
                            className="linkedin"
                            target="_blank"
                            style={{
                              backgroundColor: "#124345",
                              color: "#fff",
                              width: "31px",
                              borderRadius: "5px",
                              marginLeft: "20px",

                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faLinkedinIn}
                              className="svg-inline--fa fa-linkedin-in"
                            />
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/noontek.co/?hl=en"
                            className="insta"
                            target="_blank"
                            style={{
                              backgroundColor: "#124345",
                              color: "#fff",
                              width: "31px",
                              borderRadius: "5px",
                              marginLeft: "20px",

                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            }}
                          >
                            <FontAwesomeIcon icon={faInstagram} />
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
