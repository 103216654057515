import HomePage from "./Pages/HomePage";
import Navbar from "./Components/Uitily/navbar";
import Footer from "./Components/Uitily/footer";
import { Route, Routes } from "react-router-dom";
import AboutPage from "./Pages/AboutPage";
import ContactPage from "./Pages/ContactPage";
import OurServericesPage from "./Pages/OurServericesPage";
import TeamPage from "./Pages/TeamPage";
import But from "./Components/Uitily/but";
import TranslatHook from "./hook/translat-hook";
import RatingPage from "./Pages/RatingPage";
import HederOurServeices from "./Components/OurServerices/TheId/HederOurServeices";

function App() {
  const [lang] = TranslatHook();
  return (
    <div className={`${lang === "ar" ? "bodyar" : "bodyen"}`}>
      <But />
      <div style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
        <Navbar />
      </div>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<OurServericesPage />} />
        {/* <Route path="/team" element={<TeamPage />} /> */}
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/rating" element={<RatingPage />} />
        <Route path="/oursvices/:id" element={<HederOurServeices />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
