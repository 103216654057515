import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setLanguae } from '../rudex/actions/actions';
import TranslatHook from './translat-hook';

const TransNavbarHook = () => {

    const [, content] = TranslatHook();
    const options = [
        { value: 'en', label: content.English },
        { value: 'tr', label: content.Türkçe },
        { value: 'ar', label: content.Arabic },
    ];
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.lang);
    const [selectedOption, setSelectedOption] = useState(options.find((option) => option.value === lang));
    const [isMenuOpen, setMenuOpen] = useState(false);
    const handleLanguageChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        dispatch(setLanguae(selectedOption.value));
        window.scrollTo({ top: 0 });


    };
    const handleMouseEnter = () => {
        setMenuOpen(true);

    };

    const handleMouseLeave = () => {
        setMenuOpen(false);

    };


    return [options, lang, setSelectedOption, setMenuOpen, selectedOption, isMenuOpen, handleLanguageChange, handleMouseEnter, handleMouseLeave]
}

export default TransNavbarHook