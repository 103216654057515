import React from "react";
import CountNumber from "../test/testForaTimer";
import img from "../../images/pexels-abhilash-sahoo-4413295.jpg";
import TranslatHook from "../../hook/translat-hook";
const homeStatics = () => {
  const [, content] = TranslatHook();

  return (
    <>
      <section className="statics" style={{ paddingBottom: "50px" }}>
        <h1 className="mainH">{content.OurStatics}</h1>
        <div
          id="bg-counters"
          className="padding bg-counters parallax"
          style={{
            backgroundImage: ` url(${img})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "center 0px",
          }}
        >
          <div className="container">
            <div className="row align-items-center text-center">
              <div className="overlay overlay-dark opacity-6 z-index-0"></div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-6 bottom10 z-index-1 heigth_card">
                <div className="counters whitecolor  top10 bottom10">
                  <span className="plus">+</span>
                  <span className="count_nums font-light">
                    {" "}
                    <CountNumber to={5} speed={1} />K
                  </span>
                </div>
                <div className="counters whitecolor  top10 bottom10">
                  <h3 className="font-light whitecolor top20">
                    عدد العملاء الأفراد
                  </h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3 col-6 bottom10 z-index-1 heigth_card">
                <div className="counters whitecolor  top10 bottom10">
                  <span className="plus">+</span>
                  <span className="count_nums font-light">
                    <CountNumber to={70} speed={1} />
                  </span>
                </div>
                <div className="counters whitecolor  top10 bottom10">
                  <h3 className="font-light whitecolor top20">
                    {" "}
                    عدد العملاء المؤسسات
                  </h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-6 bottom10 z-index-1 heigth_card">
                <div className="counters whitecolor  top10 bottom10">
                  <span className="plus">+</span>
                  <span className="count_nums font-light">
                    {" "}
                    <CountNumber to={60} speed={1} />K
                  </span>
                </div>
                <div className="counters whitecolor  top10 bottom10">
                  <h3 className="font-light whitecolor top20">
                    عدد الأجهزة التي تم تجديدها وصيانتها{" "}
                  </h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-6 bottom10 z-index-1 heigth_card">
                <div className="counters whitecolor  top10 bottom10">
                  <span className="plus">+</span>
                  <span className="count_nums font-light">
                    {" "}
                    <CountNumber to={9} speed={0.1}></CountNumber>M
                    <span style={{ float: "right" }}>$</span>
                  </span>
                </div>
                <div className="counters whitecolor  top10 bottom10">
                  <h3 className="font-light whitecolor top20">
                    حجم مبيعات الشركة
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default homeStatics;
