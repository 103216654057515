import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import apple from "../../images/NoonTekImage/Apple.png"
import asus from "../../images/NoonTekImage/Asus.png"
import dahawa from "../../images/NoonTekImage/Dahawa.png"
import hp from "../../images/NoonTekImage/HP.png"
import hikvision from "../../images/NoonTekImage/Hikvision.png"
import lenovo from "../../images/NoonTekImage/Lenovo.png"
import tpLink from "../../images/NoonTekImage/TPLINK_Logo_2.svg.png"
import xiaomi from "../../images/NoonTekImage/Xiaomi-Logo.png"
import zk from "../../images/NoonTekImage/ZK.png"
import jbl from "../../images/NoonTekImage/jbl.png"
import samsung from "../../images/NoonTekImage/samsung.png"
import { Container } from 'react-bootstrap';
import '../../styles/Partners.css'

const Partners = () => {
    const [slidesToShow, setSlidesToShow] = useState(5);

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth <= 640 && windowWidth > 550) {
                setSlidesToShow(3);
            }
            else if (windowWidth <= 550) {
                setSlidesToShow(2);
            }
            else {
                setSlidesToShow(5);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const settings = {
        infinite: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 4000,
    };

    return (
        <div className="mainContainer" id='our-partnerss'>
            <Container style={{ padding: '2rem !important' }}>
                <Slider {...settings} className='slider-1'>
                    <div className="container HelloMerhaba " style={{ objectFit: 'cover' }}>
                        <img className='' alt="" src={apple} />
                    </div>
                    <div className="container HelloMerhaba" style={{ objectFit: 'cover' }}>
                        <img alt="" src={asus} />
                    </div>
                    <div className="container HelloMerhaba" style={{ objectFit: 'cover' }}>
                        <img alt="" src={dahawa} />
                    </div>
                    <div className="container HelloMerhaba" style={{ objectFit: 'cover' }}>
                        <img alt="" src={hp} />
                    </div>
                    <div className="container HelloMerhaba" style={{ objectFit: 'cover' }}>
                        <img alt="" src={hikvision} />
                    </div>
                    <div className="container HelloMerhaba" style={{ objectFit: 'cover' }}>
                        <img alt="" src={lenovo} />
                    </div>
                    <div className="container HelloMerhaba" style={{ objectFit: 'cover' }}>
                        <img alt="" src={tpLink} />
                    </div>
                    <div className="container HelloMerhaba" style={{ objectFit: 'cover' }}>
                        <img alt="" src={xiaomi} />
                    </div>
                    <div className="container HelloMerhaba" style={{ objectFit: 'cover' }}>
                        <img alt="" src={zk} />
                    </div>
                    <div className="container HelloMerhaba" style={{ objectFit: 'cover' }}>
                        <img alt="" src={jbl} />
                    </div>
                    <div className="container HelloMerhaba" style={{ objectFit: 'cover' }}>
                        <img alt="" src={samsung} />
                    </div>
                </Slider>
            </Container>
        </div>
    );
};

export default Partners;
