
//Types

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_CONTENT = 'SET_CONTENT';




export const setLanguae = (lang) => {
    return {
        type: SET_LANGUAGE,
        payload: lang,
    };
};

export const SetContent = (content) => {
    return {
        type: SET_CONTENT,
        payload: content,
    }
}